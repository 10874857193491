// Header
.header {
  background-color: #fff;

  .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: none;
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.header-top {
  padding: 1.6rem 0;
  color: #999;
  border-bottom: 1px solid #e7e7e7;

  .header-right {
    ul {
      display: flex;
      font-size: 1.2rem;
      font-family: $second-font-family;
      margin-bottom: 0;

      li:not(:last-child) {
        padding-right: 1.8rem;
        margin-right: 1.8rem;
        border-right: 1px solid rgba(#e7e7e7, 0.7);
      }

      a {
        text-transform: uppercase;
      }
    }
  }
}

.header-middle {

  .main-nav > li {
    padding: 0;
    margin-right: 4rem;
    position: relative;

    .megamenu,
    &.sf-with-ul > ul {
      left: 0;
      top: 100%;
    }

    .megamenu {
      left: -200%;
    }

    > a {
      font: 700 13px $second-font-family;
      padding: 2rem 0;
      transition: none;

      &.sf-with-ul {
        padding-right: 2rem;
        margin-right: -2rem;

        &:after {
          font-size: 14px;
          right: 5px;
        }
      }
    }

    &:hover > a,
    &.show > a,
    &.active > a {
      background-color: #fff;
    }
  }

  .main-nav {
    margin-right: 0;

    .main-nav-item {
      cursor: pointer;

      > a {
        color: #fff;

        i {
          font-size: 1.6rem;
          margin-right: 5px;
        }
      }

      &:hover > a {
        color: #fff;
      }
    }
  }

  form {
    margin-bottom: 0;
  }
}

.header-left,
.header-center,
.header-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-width: 15%;
}

.header-left {
  text-align: right;
}

.header-center {
  margin: auto;
}

.header-right {
  justify-content: flex-end;
  padding-top: 1px;

  .phone {
    font-size: 3rem;
    color: #eeb933;
    stroke: #333;
    margin-right: 0.7rem;
    stroke-width: 30;
  }

  .title {
    font-weight: bold;
    font-size: 1.1rem;
    color: #767f84;
  }

  .sub-title {
    color: #333;
    font-weight: bold;
    font-size: 1.7rem;
  }
}

.header-user {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;

  i {
    font-size: 27px;
    color: black;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    padding: 0.5rem;
    margin-right: 1rem;
  }

  &:hover i {
  }
}

.header-userinfo {
  letter-spacing: 0.01em;

  span {
    font-size: 1.2rem;
    color: #777;
    font-weight: 500;
  }

  h4 {
    font-size: 1.3rem;
    font-weight: 600;
  }
}

.porto-icon {
  font-size: 28px;
  margin-right: 2.2rem;
  color: black;
  display: inline-block;
}

@include media-breakpoint-down(sm) {
  .header .logo img {
    top: -0.5rem;
  }
  .header-left {
    margin: auto;
  }
}

.header-dropdown {
  position: relative;
  text-transform: uppercase;
  font-family: $second-font-family;
  margin-right: 2rem;
  padding-right: 2rem;
  border-right: 1px solid rgba(#e7e7e7, 0.7);

  &:last-child {
    border-right: none;
  }

  > a {
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 1.2rem;
    font-size: 1.2rem;
    font-weight: 500;
    cursor: default;

    &:after {
      display: inline-block;
      position: absolute;
      top: 40%;
      right: -3px;
      transform: translateY(-50%);
      font-family: "porto";
      font-size: 1.6rem;
      line-height: 1;
      content: "\e81c";
    }

    &:hover {
    }
  }

  a {
    img {
      display: inline-block;
      max-width: 16px;
      height: auto;
      margin-right: 0.6rem;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.header-menu {
  position: absolute;
  top: 140%;
  left: 0;
  min-width: 100%;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  transition: all 0.25s;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  z-index: 20;

  .header-right & {
    right: 0;
    left: auto;
  }

  .header-dropdown:hover > & {
    opacity: 1;
    visibility: visible;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      margin: 0;
    }

    a {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: 2px 9px;
      white-space: nowrap;
    }
  }

  a {
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 0;

    &:hover,
    &:focus {
      background-color: #ccc;
      color: #fff;
    }
  }
}

.header-bottom {
  display: flex;
}

.service-widget {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $second-font-family;
  padding: 1.1rem 0;
  color: #fff;

  .service-icon {
    font-size: 2.4rem;
    margin: 0 0.7rem 0 0;
  }

  .service-title {
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}

@media (max-width: 991px) {
  .header-top,
  .header-bottom,
  .main-nav {
    display: none;
  }
}
