@include media-breakpoint-down(sm) {
  #cart-mobile:not(.fixed-top) {
    margin: 0px -20px;
  }
  .order-summary-wrapper {
    h3 {
      margin: 0;
      padding: 2rem 0;
      font-size: 1.6rem;
    }
  }
}

// Cart

#order-cart-section {
  .product-title {
    span {
      white-space: normal;
    }
  }

  img {
    max-width: 6rem;
    margin-right: 1rem;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
  }
}

.product-col {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  .product-image-container {
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    max-width: 70px;
    margin-right: 1.8rem;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .product-title {
    vertical-align: middle;
    font: 400 1.5rem/1.25 $font-family;
    color: #0e2f40;
  }
}
