@import "~bootstrap/scss/mixins/breakpoints";

// Footer
.footer {
  background-color: #1b1c1d;
  font-family: $font-family;

  .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: none;
    padding: 0 3.125%;
  }

  .social-icons {
    display: flex;
    align-items: center;
  }

  .social-icon {
    margin: 0;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    i {
      transition: all 0.25s;
      font-size: 1.6rem;
      color: #fff;
    }

    &:hover {
      background-color: #fff;

      i {
      }
    }

    & + .social-icon {
      margin-left: 4rem;
    }
  }

  .social-link {
    .link-title {
      font-size: 1.7rem;
      line-height: 3rem;
      font-weight: 600;
      color: #999;
    }

    .link-sub-title {
      font-size: 1.2rem;
      line-height: 1.2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    a {
      font-size: 2.2rem;
      font-weight: 700;
    }

    a:hover {
    }
  }

  .widget-cards {
    svg {
      margin-left: 13px;
    }
  }
}

.footer .widget .widget-content.widget-reviews {
  a {
  }

  a:hover {
  }

  .fa-star,
  .fa-star-half-stroke {
    font-size: 2.2rem;
    margin-top: 0.6rem;
  }
}

.footer-middle {
  padding: 4rem 0 2rem;
  color: #777;

  .container > .row > div:last-child {
    text-align: right;
  }

  img {
    margin-bottom: 2rem;
  }
}

@include media-breakpoint-down(sm) {
  .footer-middle {
    padding: 2rem 0 0;
  }
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;

  border-top: 1px solid #32363c;

  p {
    font-family: $second-font-family;
    font-size: 1.3rem;
    color: #999;
    letter-spacing: 0.005em;
    margin-bottom: 0;
  }
}

.footer .widget {
  margin-bottom: 2rem;

  ul {
    margin-bottom: 0;
  }

  ul > li {
    margin-bottom: 6px;
  }

  .widget-title {
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
    margin-top: 0;
    color: #fff;
  }

  .widget-content {
    p,
    a {
      font-size: 1.3rem;
      letter-spacing: 0.005em;
    }

    p {
      margin-bottom: 0;
      line-height: 2.4rem;
    }
  }
}

// Scroll Top Button
#scroll-top {
  height: 40px;
  position: fixed;
  right: 15px;
  width: 40px;
  z-index: 9999;
  bottom: 0;
  background-color: #43494e;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1;
  padding: 11px 0;
  visibility: hidden;
  opacity: 0;
  border-radius: $border-radius $border-radius 0 0;
  transition: all 0.3s, margin-right 0s;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
}

#scroll-top:hover,
#scroll-top:focus {
  background-color: #3a4045;
}

#scroll-top.fixed {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
