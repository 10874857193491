// Category
.sidebar-shop,
.sidebar-product {
  font-size: $sidebar-font-size;

  .widget {
    @include clearfix;
    margin: 0;
    padding-bottom: 3.5rem;

    .config-swatch-list {
      margin-top: 0.3rem;

      li {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 0;
        margin-bottom: 1.2rem;
        font-size: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          margin-right: 1.5rem;
          width: auto;
        }

        &.active a:before {
          left: 15%;
        }
      }
    }
  }

  label,
  input {
    cursor: pointer;
  }

  .widget-title {
    margin: 0;
    color: #444;
    font: 700 1.2rem/1.1 $font-family;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-bottom: 1rem;

    a {
      display: block;
      position: relative;

      &:hover,
      &:focus {
        color: inherit;
        text-decoration: none;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        right: 0.5rem;
        top: 50%;
        width: 10px;
        height: 2px;
        background-color: #444;
        margin-top: -1px;
        transition: all 0.35s;
      }

      &.collapsed:after {
        transform: rotate(-90deg);
      }
    }
  }

  .widget-body {
    @include clearfix;
    padding-top: 1.7rem;
    padding-left: 0;
  }

  .widget-featured {
    position: relative;

    .widget-body {
      padding-top: 2.3rem;
    }

    .product-sm:last-child {
      margin-bottom: 0;
    }
  }
}

.cat-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: #7a7d82;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.product-category {
  color: #1d2127;
  margin-bottom: 2rem;
  position: relative;
}

.product-default .category-list {
  font-family: Open Sans, sans-serif;
}

.product-default .product-title a {
  font-family: Open Sans, sans-serif;
  color: #222329;
}
