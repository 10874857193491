// Layout
// Body Search

.step-heading {
  margin: 0 0 2rem 5px;
  font-size: 120%;
}

.check {
  white-space: nowrap;

  span {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.6rem;
  }

  img {
    height: 2.6rem;
    width: auto;
    display: inline-block;
    margin: -0.5rem 2rem 0 1rem;
  }
}

.image-figure {
  figure {
    margin-bottom: 1.4rem;
  }

  figure img {
    height: auto;
  }

  figcaption {
    background-color: #279d41;
    text-align: center;
    color: #fff;
    padding: 1.2rem 0;
    font-size: 1.5rem;
    font-weight: 500;
    transition: background-color 200ms linear;
  }

  &:hover figcaption {
    background-color: #111;
  }
}

.border-bottom-primary {
  width: 100%;
}

.border-bottom-primary-thick {
  width: 100%;
}

@include media-breakpoint-down(sm) {
  html {
    font-size: 9px;
  }
}

#barcode-scanner {
  position: relative;

  video {
    width: 100%;
    height: max-content;
    object-fit: cover;
  }

  canvas {
    display: none;
  }
}

// Fix to styling issues.
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.fa-instagram {
  color: transparent;
  background: radial-gradient(
                  circle at 30% 107%,
                  #fdf497 0%,
                  #fdf497 5%,
                  #fd5949 45%,
                  #d6249f 60%,
                  #285aeb 90%
  );
  background: -webkit-radial-gradient(
                  circle at 30% 107%,
                  #fdf497 0%,
                  #fdf497 5%,
                  #fd5949 45%,
                  #d6249f 60%,
                  #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}

.circle-text {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 3.2rem;
  font-weight: bold;
  font-size: 1.8rem;
  margin: auto;
}

.border-bottom {
  border-bottom: 1px solid #cccccc !important;
}

.fade:not(.show) {
  display: none;
}

.react-datepicker {
  border: 1px solid #ccc;
  border-top: none;

  .react-datepicker__navigation--next {
    border-left-color: #fff;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #fff;
  }

  .react-datepicker__header {
    border-bottom: 1px solid #ccc;
    padding-top: 0;

    .react-datepicker__current-month {
      background-color: #279d41;
      padding: 0.7rem 0;
      color: #fff;
      font-weight: normal;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    .react-datepicker__day-names {
      background-color: #f5f5f5;

      .react-datepicker__day-name {
        margin: 0.2rem 0.2rem 0.1rem 0.2rem;
      }
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
  }
}

.react-tel-input {
  .form-control {
    @extend .form-control;
    @apply text-lg;
    width: 100%;
    padding-left: 48px !important;
    border-color: #ededed !important;
  }

  .flag-dropdown {
    border: none;
    margin: 1px;
  }

  .country-list {
    margin-top: 0;

    .search {
      padding: 10px 17px 6px 10px;

      .search-box {
        width: 100%;
        font-size: 1.3rem;
      }
    }

    .country {
      font-size: 1.3rem;
    }
  }
}

.color-primary {
  color: #228939;
}

.btn-primary {
  &:hover,
  &:focus {
    background-color: #228939 !important;
  }
}

.color-panel {
  &.color-primary {
  }
}

.cat-checkbox {
  li {
    label {
      margin-left: 0.5rem;
      color: #7a7d82;
      font-size: 1.2rem;
      user-select: none;
      transition: all 0.2s ease-out;
    }
  }
}

.layout-search {
  position: relative;
  border: 0;
  font-size: 0;
  line-height: 1;

  .layout-search-wrapper {
    align-items: center;
    overflow: visible;
    border: none;
    width: 100%;
    z-index: 999;
    display: flex;
    @include clearfix;

    &.show {
      display: flex;
    }

    &:before {
      display: none;
      position: absolute;
      top: -20px;
      right: -280px;
      width: 20px;
      height: 20px;
      border: 10px solid transparent;
      border-bottom-color: #dbe0e2;
      content: "";
    }
  }

  form {
    margin: 0;
  }

  .select-custom {
    flex: 0 0 200px;
    margin: 0;
    background: #f4f4f4;

    &:after {
      right: 1.8rem;
      color: #8d8d8d;
      font-size: 1.4rem;
    }
  }

  .form-control,
  select {
    height: 40px;
    font: 400 1.3rem $second-font-family;
    background: #f4f4f4;
    margin: 0;
    border: none;
  }

  .form-control {
    font-size: 1.3rem;
    flex: 1 1 auto;
    width: 320px;
    height: 40px;
    margin: 0;
    padding: 4px 22px;
    border-right: none;
    border-radius: 19px 0 0 19px;
    color: #8d8d8d;
    line-height: 20px;
    box-shadow: none;

    &::-webkit-input-placeholder {
      color: #8d8d8d;
    }

    &::-moz-placeholder {
      color: #8d8d8d;
    }

    &:-ms-input-placeholder {
      color: #8d8d8d;
    }

    &::-ms-input-placeholder {
      color: #8d8d8d;
    }

    &::placeholder {
      color: #8d8d8d;
    }

    &:focus {
      border-color: #e7e7e7;
      background-color: #f4f4f4;
    }
  }

  select {
    width: 100%;
    border-left: 1px solid #e7e7e7;
    line-height: 36px;
    color: #8d8d8d;
    padding: 1px 10px 1px 10px;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  .btn {
    flex: 0 0 65px;
    height: 40px;
    min-width: 0;
    color: #111;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1;
    border: none;
    padding: 0 3px 0 0;
    margin: 0;
    cursor: pointer;
    border-radius: 0 19px 19px 0;
    background-color: #f0c14b;

    .fa.fa-search {
      font-weight: 400;
      margin-top: 0.2rem;
    }

    i {
      &:before {
        margin: 0;
      }
    }

    &:hover,
    &:focus {
      background-color: #eeb933;
    }
  }

  .search-toggle {
    display: block;
    line-height: 1;
    min-width: 2.5rem;
    padding: 1rem 0;
    font-weight: 700;
    color: black;
    text-align: center;

    i {
      display: inline-block;
      font-size: 25px;
      transition: none;
    }

    &:hover,
    &:hover i {
    }
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container {
  padding: 0 10px;
}

.page-wrapper {
  position: relative;
  transition: transform 0.35s;

  .mmenu-active & {
    transform: translateX(250px);
  }
}

.main {
  flex: 1 1 auto;
}

.row {
  margin-right: -10px;
  margin-left: -10px;

  [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.ajax-overlay {
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1041;
}

@include mq(xl) {
  .padding-left-lg {
    padding-left: 35px;
  }

  .padding-right-lg {
    padding-right: 35px;
  }

  .col-xl-5col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-xl-7col {
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }

  .col-xl-8col {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1680px;
    padding: 0 10px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 88%;
  }
}

@media (max-width: 991px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
  }
}
