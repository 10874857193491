// Variables
$font-size: 1.3rem !default;
$sidebar-font-size: 1.2rem !default;
$footer-font-size: 1.4rem !default;
$input-font-size: 1.3rem !default;
$button-font-size: 1.4rem !default;

$secondary-color: #27839d !default;
$secondary-color-dark: #2f3946 !default;
$link-color: $secondary-color-dark !default;

$border-radius: 0 !default;

$body-text: #545454 !default;
$light-text: #8a8a8a !default;
$headings-text: #282d3b !default;

$font-family: var(--font-poppins) !default;
$second-font-family: var(--font-open-sans) !default;

$breakpoints: (
        xs: 480px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px,
        xlst: 1920px,
);

$max-breakpoints: (
        xs: 479px,
        sm: 575px,
        md: 767px,
        lg: 991px,
        xl: 1199px,
        xxl: 1599px,
);
