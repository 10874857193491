@import "~bootstrap/scss/mixins/breakpoints";

// Product Element
.product-default {
  color: #777;
  background-color: #f5f5f5;
  padding: 20px 10px 10px 10px;
  border: solid 1px transparent;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .button-container {
    display: flex;
    align-items: flex-end;
  }

  &:hover {
    border-color: #666;
  }

  a {
    color: #777;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
    }
  }

  figure {
    margin-bottom: 1.6rem;
    position: relative;

    img {
      transition: all 0.5s;
      margin: auto;
      mix-blend-mode: multiply;
    }

    img:last-child {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      left: 0;
    }

    img:first-child {
      opacity: 1;
      position: relative;
      width: auto;
      height: 130px;
      max-width: 100%;
    }
  }

  .label-group {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;

    & > * {
      margin-bottom: 5px;
    }
  }

  .product-label {
    text-transform: uppercase;
    padding: 5px 11px;
    font: 600 1rem/1 $font-family;
    color: #fff;

    &.label-sale {
      background-color: #62b959;
    }

    &.label-cut {
      background-color: #e27c7c;
    }
  }

  .product-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .category-list {
    font: 400 1rem $font-family;
    line-height: 1.7;
    opacity: 0.8;
    text-transform: uppercase;
  }

  .product-title {
    font: 400 1.3rem $font-family;
    letter-spacing: -0.01em;
    line-height: 1.35;
    margin-bottom: 0.72rem;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    max-height: 4.4rem;
  }

  .btn-icon-wish,
  .btn-quickview-1 {
    display: flex;
    border: 1px solid #ddd;
    font-size: 1.6rem;
    margin: 0 2px;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.25s ease;
    transform: translateX(200%);

    &.checked {
      color: #e27c7c;

      i:before {
        content: "\e88a";
      }
    }
  }

  .btn-quickview-1 {
    font-size: 1.4rem;
    transform: translateX(-200%);
  }

  .btn-add-cart {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 0 1rem;
    font: 1.4rem $second-font-family;
    line-height: 32px;
    margin: 0 2px;
    cursor: pointer;
    transition: all 0.25s ease;

    i {
      font-size: 1.8rem;
      margin-bottom: 2px;

      &:before {
        margin: 0 4px 0 0;
      }
    }
  }

  &:hover {
    z-index: 2;

    figure {
      img:first-child {
        opacity: 0;
      }

      img:last-child {
        opacity: 1;
      }
    }

    .btn-add-cart {
      color: #fff;
    }

    .product-action {
      a {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.product-price {
  color: #465157;
  font: 600 1.5rem $font-family;
}

.price-box {
  margin-bottom: 1.2rem;
}

.inner-quickview {
  figure {
    position: relative;
    text-align: center;

    .btn-quickview-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 0.025em;
      font-family: $second-font-family;
      text-transform: uppercase;
      visibility: hidden;
      opacity: 0;
      height: auto;
      padding: 1.1rem;
      color: #fff;
      transform: none;
      margin: 0;
      border: none;
      transition: all 0.3s ease-out;
      text-align: center;
    }

    .btn-quickview-2 {
      @extend .btn-quickview-1;
      background-color: darkred;
      padding: 0.8rem;
    }

    .btn-quickview-1:hover {
      opacity: 1;
    }
  }

  .product-details {
    align-items: flex-start;
  }

  .product-title {
    color: #212529;
    white-space: pre-wrap;

    &:hover {
    }
  }

  .category-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .btn-icon-wish {
      font-size: 1.5rem;
      transform: none;
      opacity: 1;
      width: auto;
      height: auto;
      border: none;
      margin-right: -2px;
    }
  }

  &:hover {
    .btn-quickview-1 {
      visibility: visible;
      opacity: 0.9;
    }
  }
}

.inner-icon {
  figure {
    position: relative;

    .btn-icon-group {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }

    .btn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ddd;
      border-radius: 50%;
      margin: 0 0 5px;
      width: 36px;
      height: 36px;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      transform: none;

      i {
        font-size: 1.6rem;
        margin-bottom: 0;

        &:before {
          margin: 0;
        }
      }

      i.icon-bag {
        font-size: 1.8rem;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  &:hover {
    .btn-icon {
      background-color: #fff;
      border-color: #ddd;
      color: black;
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (min-width: 481px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-xs-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.product-default-wrapper {
  height: 100%;
}

@include media-breakpoint-down(sm) {
  .product-default-wrapper {
    padding: 0;

    .product-default {
      figure {
        img:first-child {
          height: 20rem;
          mix-blend-mode: multiply;
          margin: auto;
          width: auto;
        }
      }

      .category-list {
        font-size: 1.6rem;
      }

      .product-title {
        font-size: 1.8rem;
        line-height: 2.2rem;
        height: auto;
        overflow: visible;
      }

      .product-details {
        overflow: visible;
      }
    }
  }
}
