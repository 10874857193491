// Buttons

.react-switch-checkbox {
  display: none;
}

.react-switch-label {
  display: block;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 3rem;
  height: 1.7rem;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin: 0 !important;

  svg {
    color: white;
    height: 0.9rem;
    margin: 4.3px 1.5px;

    &.fa-times {
      margin-left: 1px;
    }
  }

  .react-switch-button {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  &:active .react-switch-button {
    width: 2rem;
  }
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.btn-medium {
  margin: 0.3rem 0;
  height: 5rem;
  width: 100%;
  padding: 1rem 1.5rem !important;

  span {
    margin-left: -0.6rem;
  }
}

.btn-big {
  margin: 0.7rem 0;
  height: 6rem;
  width: 100%;
}

.btn.btn-select {
  border-color: #ccc;
  color: #777;
  background-color: transparent;
  background-image: none;
  text-transform: none;
  width: 100%;
  margin-bottom: 0.7rem;

  .check {
    position: absolute;
    right: 0;
    padding-right: 2rem;
  }

  &.btn-active {
    border-color: #28a745;
    color: #28a745;
  }
}

.btn.btn-spinner {
  border-color: #ccc;
  background-color: #f5f5f5;
  background-image: none;
  color: #777;
  width: 1.8rem;

  &:not(.no-hover):hover {
    background-color: #ddd;
  }
}

.btn.no-hover {
  cursor: auto !important;
}

.btn {
  padding: 1rem 2.4rem;
  font-size: $button-font-size;
  line-height: 1.5;
  font-family: $second-font-family;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  border-radius: $border-radius;
  transition: all 0.3s;
}

.btn-xsm {
  line-height: 32px;
  margin: 0 2px;
  font-size: 1.4rem;
  padding: 0;
}

.btn-light-darker,
.btn-light-darker:active {
  color: $secondary-color-dark;
  background-color: $gray-300;
  border-color: $gray-300;

  &:hover {
    color: $secondary-color-dark;
    background-color: $gray-400;
    border-color: $gray-200;
  }
}

.btn-sm {
  font-size: 1.3rem;
  padding: 1rem 1.5rem;
  letter-spacing: 1px;
}

.btn-xsm {
  font-size: 1.2rem;
  padding: 0.9rem 1.2rem;
  letter-spacing: 1px;
  line-height: 1.4rem;
}

.btn-link {
  padding-top: 0;
  padding-bottom: 0;
  color: $secondary-color;
  text-transform: initial;
  letter-spacing: 0;
  font-size: 13px;
  min-width: 0;
  font-family: $font-family;

  &:hover,
  &:focus {
    color: $secondary-color;
    text-decoration: underline;
  }
}

.btn-md {
  padding: 1rem 2.4rem;
  font-size: 12px;
  letter-spacing: 0.025em;
  text-shadow: none;
}

a:focus {
  outline: none;
}
